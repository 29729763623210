import styled from "styled-components"

export const Footer = styled.footer({
  opacity: 0.7,
  fontSize: "0.9em",
  display: "flex",
  flexDirection: "column",
  paddingTop: 20,
  paddingBottom: 20,
  justifyContent: "center",
  alignItems: "center",
})

export const Link = styled.a({
  color: "inherit",
})
