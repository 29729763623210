/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import GlobalStyles from "../styles/GlobalStyles"

import { ThemeProvider } from "styled-components"
import { Light } from "../styles/Themes"
import Footer from "./Footer"

import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={Light}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=PT+Serif|Roboto&display=swap"
          rel="stylesheet"
        />
        <meta name="theme-color" content="#ebfefe" />
      </Helmet>
      <GlobalStyles />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "99vh",
        }}
      >
        <GlobalStyles />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {children}
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
