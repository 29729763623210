import React from "react"
import * as S from "./styles"

const Footer = () => {
  return (
    <S.Footer>
      <div style={{ marginBottom: 5 }}>
        © 2019 &nbsp; &bull; &nbsp;
        <S.Link
          target="_blank"
          rel="noreferrer"
          href="https://creativecommons.org/licenses/by-nc/4.0/"
        >
          CC BY-NC 4.0
        </S.Link>
      </div>
      <div>
        Made with ❤ by &nbsp;
        <S.Link target="_blank" rel="noreferrer" href="https://praneet.dev">
          Praneet Rohida
        </S.Link>
      </div>
    </S.Footer>
  )
}

export default Footer
